:root {
  --color_cream: #e7ddd1;
  --color_purple: #d080e3;
  --color-black: #000000;
  --gooper: "VCGooper-Light";
}

body {
  margin: 0;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}

/* >>>>>>>> FONT <<<<<<<< */

@font-face {
  font-family: "ABC Oracle Light Regular";
  src: url("../public/fonts/ABCOracle-Light.otf") format("opentype");
}

@font-face {
  font-family: "VCGooper-Light";
  src: url("../public/fonts/VCGooper-Light.otf") format("opentype");
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h1,
h2,
h3,
h4,
h5,
h6,
.ant-modal-title,
.ant-btn,
.ant-table-thead {
  font-family: var(--gooper);
  color: var(--color-black);
}

.font-gooper {
  font-family: var(--gooper);
}

/* >>>>>>>> TABLE <<<<<<<< */

table {
  border: 1px solid;
  border-radius: 8px !important;
}

th.ant-table-cell:before {
  content: none !important;
}

tr.ant-table-row:last-child > td {
  border-bottom: none !important;
}

tr.ant-table-row:last-child > td.ant-table-cell-row-hover:first-child {
  border-bottom-left-radius: 8px;
}

tr.ant-table-row:last-child > td.ant-table-cell-row-hover:last-child {
  border-bottom-right-radius: 8px;
}

/* >>>>>>>> MENU <<<<<<<< */

/* Handle menu overall border */

.ant-btn-default:not(:disabled):hover {
  border-color: var(--color_purple) !important;
  color: var(--color_black) !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: 1px solid var(--color-black);
  border-radius: 8px;
}

/* >>>>>>>> INPUT <<<<<<<< */

.ant-input-outlined:hover {
  border-color: var(--color_purple);
}

/* >>>>>>>> BUTTON <<<<<<<< */

.ant-btn:not(:disabled):hover {
  color: var(--color-black) !important;
}
